import * as React from "react";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import Products from "../components/RentProducts";
import CommonButton from "../components/Button";
import { getIsVertical } from "../utils/getIsVertical";

import cenradis from "../files/Rūre_pakalpojumu_cenrādis.pdf";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../styles/mediciniskie-pakalpojumi-un-tehnisko-paliglidzeklu-noma.css";

export default function Rent() {
	const isVertical = getIsVertical();
	return (
		<Layout pageId={PageId.Rent}>
			<Seo
				title="Medicīniskie pakalpojumi un palīglīdzekļu noma"
				description="Rūre sniedzam arī cita veida medicīnisko aprūpi pacienta mājās: ārstu - speciālistu mājas vizītes, medikamentu ievadīšanu, brūču aprūpi, klīnisko analīžu noņemšanu un nogādāšanu laboratorijā, kā arī piedāvājam palīglīdzekļu nomu un piegādi."
			/>
			<div className="contentBlock topTextSection">
				<p className="rentTopSubText">Rūre sniedzam arī cita veida medicīnisko aprūpi pacienta mājās:<br />{isVertical ? <br /> : ""} ārstu - speciālistu mājas vizītes, medikamentu ievadīšanu,{isVertical ? "" : <br />} brūču aprūpi, klīnisko analīžu noņemšanu un nogādāšanu laboratorijā,{isVertical ? "" : <br />} kā arī piedāvājam palīglīdzekļu nomu un piegādi.</p>
				<h1>Arī šajā gadījumā mūsu pamatprincips -{isVertical ? "" : <br />} individuāla, konkrētā pacienta vajadzībām{isVertical ? "" : <br />} atbilstoša pieeja.</h1>
			</div>
			<div className="contentBlock">
				<div className="carePlan">
					<div>
						<h2>Rūre multiprofesionālās veselības aprūpes plāns iekļauj Rūre medicīniskos un sociālos pakalpojumus, kas tiek regulāri sniegts klienta dzīvesvietā.</h2>
						<p>Plāna cena ir atkarīga no klienta{isVertical ? "" : <br />} nepieciešamībām.</p>
					</div>
					<div>
						<p className="includedInCarePlan">Plānā iekļauts:</p>
						<div className="carePlanList">
							<div>veselības aprūpes pakalpojumu koordinēšana</div>
							<div>plānveida ambulatoro pakalpojumu plānošana, organizēšana ārstniecības iestādēs (speciālistu konsultācijas, procedūras)</div>
							<div>plānveida diagnostisko izmeklējumu<br /> plānošana, organizēšana</div>
							<div>psihoemocionālā atbalsta<br /> organizēšana un sniegšana<br /> (psihologa, psihoterapeita konsultācijas)</div>
							<div>diennakts specializētā transporta pakalpojumi</div>
							<div>ārsta mājas vizītes<br /> (skaits saskaņā ar veselības aprūpes plānu)</div>
							<div>ārsta palīgu mājas vizītes<br /> (skaits saskaņā ar veselības aprūpes plānu)</div>
							<div>veselības stāvokļa dinamiskā kontrole</div>
						</div>
					</div>
				</div>
			</div>
			<div className="contentBlock pricePDF">
				<h1>Atsevišķu Medicīnisko pakalpojumu cenrādis</h1>
				<a href={cenradis} download>
					<CommonButton>LEJUPLĀDĒT PDF</CommonButton>
				</a>
			</div>
			<div className="contentBlock noPadding">
				<div className="rentDescriptionBlock">
					<div className="rentDescriptionBlockTitle">
						<p>Funkcionālo palīglīdzekļu noma</p>
					</div>
					<div>
						<p>Piedāvājam gan privātpersonām, gan uzņēmumiem. Drošības{isVertical ? "" : <br />} naudas iemaksa nav jāveic, minimālais nomas laiks — 1 mēnesis.</p>
						<h1>Tehniskos palīglīdzekļus nodrošinām Kurzemē, Latgalē, Rīgā un Pierīgā.</h1>
					</div>
				</div>
			</div>
			<Products />
			<div className="contentBlock">
				<div className="contactsSection">
					<div className="left">
						<p>Sazinies ar mums!</p>
						<div>
							<h2>Rīga</h2>
							<h2>Tel. nr. <a href="tel:+3718884">8884</a> <br /><a href="mailto:info.riga@rure.lv">info.riga@rure.lv</a></h2>
						</div>
					</div>
					<div className="right">
						<div>
							<p>Kurzemes reģions</p>
							<h2>Liepāja, Talsi, <br />Ventspils, Kuldīga, <br />Tukums, Saldus</h2>
							<h2>Tel. nr. <a href="tel:+3718882">8882</a> <br /><a href="mailto:info.kurzeme@rure.lv">info.kurzeme@rure.lv</a></h2>
						</div>
						<div>
							<p>Latgales reģions</p>
							<h2>Daugavpils, <br />Rēzekne</h2>
							<h2>Tel. nr. <a href="tel:+3718886">8886</a> <br /><a href="mailto:info.latgale@rure.lv">info.latgale@rure.lv</a></h2>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}
