import * as React from "react";
import Slider, { Settings } from "react-slick";
import { StaticImage } from "gatsby-plugin-image";

import ChevronLeft from "../components/ChevronLeft";
import ChevronRight from "../components/ChevronRight";
import { getIsVertical } from "../utils/getIsVertical";

import "./RentProducts.css";

const sliderSettings: Settings = {
	dots: true,
	arrows: false,
	accessibility: false,
};

const RentProducts = () => {
	const sliderRef = React.useRef<Slider>(null);
    const isVertical = getIsVertical();
    const [showAll, setShowAll] = React.useState(!isVertical);

	const handleChevronClick = React.useCallback((isRight: boolean, sliderRef: React.MutableRefObject<Slider | null>) => {
		if (sliderRef.current) {
			sliderRef.current[isRight ? "slickNext" : "slickPrev"]();
		}
	}, []);

    return (
        <div>
            <table className="rentTable">
                <tbody>
                    <tr>
                        <td className="tableData">
                            <div className="dataWrapper">
                                <div className="imageWrapper">
                                    <Slider {...sliderSettings} className="carousel" ref={sliderRef}>
                                        <StaticImage
                                            src="../images/tehnisko-paliglidzeklu-noma/bedTwo/bed1.png"
                                            alt="Elektriskā funkcionāla ārstniecības gulta (1)"
                                            quality={95}
                                            formats={["auto", "webp", "avif"]}
                                            placeholder="blurred"
                                        />
                                        <StaticImage
                                            src="../images/tehnisko-paliglidzeklu-noma/bedTwo/bed2.png"
                                            alt="Elektriskā funkcionāla ārstniecības gulta (2)"
                                            quality={95}
                                            formats={["auto", "webp", "avif"]}
                                            placeholder="blurred"
                                        />
                                        <StaticImage
                                            src="../images/tehnisko-paliglidzeklu-noma/bedTwo/bed3.png"
                                            alt="Elektriskā funkcionāla ārstniecības gulta (3)"
                                            quality={95}
                                            formats={["auto", "webp", "avif"]}
                                            placeholder="blurred"
                                        />
                                        <StaticImage
                                            src="../images/tehnisko-paliglidzeklu-noma/bedTwo/bed4.png"
                                            alt="Elektriskā funkcionāla ārstniecības gulta (4)"
                                            quality={95}
                                            formats={["auto", "webp", "avif"]}
                                            placeholder="blurred"
                                        />
                                        <StaticImage
                                            src="../images/tehnisko-paliglidzeklu-noma/bedTwo/bed5.png"
                                            alt="Elektriskā funkcionāla ārstniecības gulta (5)"
                                            quality={95}
                                            formats={["auto", "webp", "avif"]}
                                            placeholder="blurred"
                                        />
                                    </Slider>
                                    <div
                                        className="chevronWrapperLeft"
                                        onClick={() => handleChevronClick(false, sliderRef)}
                                    >
                                        <ChevronLeft />
                                    </div>
                                    <div
                                        className="chevronWrapperRight"
                                        onClick={() => handleChevronClick(true, sliderRef)}
                                    >
                                        <ChevronRight />
                                    </div>
                                </div>
                                <div className="textWrapper">
                                    <p><b>Elektriskā funkcionāla ārstniecības gulta,</b> trīs režīmi ar piecelšanās trapeci (komplektā matracis)</p>
                                    <div className="price">
                                        45
                                        <span>€</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="tableData">
                            <div className="dataWrapper">
                                <div className="imageWrapper">
                                    <StaticImage
                                        src="../images/tehnisko-paliglidzeklu-noma/bedTable.png"
                                        alt="Galdiņš gultai"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        placeholder="blurred"
                                    />
                                </div>
                                <div className="textWrapper">
                                    <p><b>Pārvietojams pielāgojamais galdiņš gultai</b></p>
                                    <div className="price">
                                        15
                                        <span>€</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="tableData">
                            <div className="dataWrapper">
                                <div className="imageWrapper">
                                    <StaticImage
                                        src="../images/tehnisko-paliglidzeklu-noma/wheelchair.png"
                                        alt="Ratiņkrēsls"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        placeholder="blurred"
                                    />
                                </div>
                                <div className="textWrapper">
                                    <p><b>Bimanuālais ratiņkrēsls</b></p>
                                    <div className="price">
                                        15
                                        <span>€</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        {showAll ? null : (
                            <td className="showAll">
                                <div onClick={() => setShowAll(true)}>
                                    SKATĪT VAIRĀK
                                </div>
                            </td>
                        )}
                        <td className={`tableData hidden ${showAll ? "shown" : ""}`}>
                            <div className="dataWrapper">
                                <div className="imageWrapper">
                                    <StaticImage
                                        src="../images/tehnisko-paliglidzeklu-noma/rollator4.png"
                                        alt="Rollators ar četriem riteņiem"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        placeholder="blurred"
                                    />
                                </div>
                                <div className="textWrapper">
                                    <p><b>Rollators ar četriem riteņiem</b></p>
                                    <div className="price">
                                        15
                                        <span>€</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className={`tableData hidden ${showAll ? "shown" : ""}`}>
                            <div className="dataWrapper">
                                <div className="imageWrapper">
                                    <StaticImage
                                        src="../images/tehnisko-paliglidzeklu-noma/walker.png"
                                        alt="Staigulis"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        placeholder="blurred"
                                    />
                                </div>
                                <div className="textWrapper">
                                    <p><b>Staigulis</b></p>
                                    <div className="price">
                                        15
                                        <span>€</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={`tableData hidden ${showAll ? "shown" : ""}`}>
                            <div className="dataWrapper">
                                <div className="imageWrapper">
                                    <StaticImage
                                        src="../images/tehnisko-paliglidzeklu-noma/rollator2.png"
                                        alt="Rollators ar diviem riteņiem"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        placeholder="blurred"
                                    />
                                </div>
                                <div className="textWrapper">
                                    <p><b>Rollators ar diviem riteņiem</b></p>
                                    <div className="price">
                                        15
                                        <span>€</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className={`tableData hidden ${showAll ? "shown" : ""}`}>
                            <div className="dataWrapper">
                                <div className="imageWrapper">
                                    <StaticImage
                                        src="../images/tehnisko-paliglidzeklu-noma/rollator3.png"
                                        alt="Rollators ar trīs riteņiem"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        placeholder="blurred"
                                    />
                                </div>
                                <div className="textWrapper">
                                    <p><b>Rollators ar trīs riteņiem</b></p>
                                    <div className="price">
                                        15
                                        <span>€</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className={`tableData hidden ${showAll ? "shown" : ""}`}>
                            <div className="dataWrapper">
                                <div className="imageWrapper">
                                    <StaticImage
                                        src="../images/tehnisko-paliglidzeklu-noma/toiletChair.png"
                                        alt="Podkrēsls"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        placeholder="blurred"
                                    />
                                </div>
                                <div className="textWrapper">
                                    <p><b>Podkrēsls</b></p>
                                    <div className="price">
                                        15
                                        <span>€</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className={`tableData hidden ${showAll ? "shown" : ""}`}>
                            <div className="dataWrapper">
                                <div className="imageWrapper">
                                    <StaticImage
                                        src="../images/tehnisko-paliglidzeklu-noma/showerChair.png"
                                        alt="Dušas krēsls / taburetes"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        placeholder="blurred"
                                    />
                                </div>
                                <div className="textWrapper">
                                    <p><b>Dušas krēsls / taburete</b></p>
                                    <div className="price">
                                        15
                                        <span>€</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className={`lastTableData hidden ${showAll ? "shown" : ""}`}>
                            <div className="lastCell">
                                <div>
                                    <div className="price">50<span>€</span></div>
                                    <p><b>Funkcionālās gultas transportēšanas un uzstādīšanas pakalpojuma cena</b> Liepājā, Daugavpilī, Ventspilī, Talsos, Kuldīgā, Tukumā, Saldū, Rīgā, Rēzeknē un Daugavpilī</p>
                                </div>
                                <div>
                                    <div className="price">30<span>€</span></div>
                                    <p>+ 0,60€ par<br /> katru kilometru</p>
                                    <p><b>Funkcionālās gultas transportēšanas un uzstādīšanas pakalpojuma cena ārpus pilsētām</b></p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default RentProducts;